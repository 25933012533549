<app-header></app-header>
<router-outlet></router-outlet>

<app-bottom-navbar *ngIf="isLoggedIn; else appFooter"></app-bottom-navbar>

<ng-template #appFooter>
  <div class="app-footer flx-align-c flx-sb">
    <div class="mada-container">
      <span>powered by</span>
      <div class="images_container">
        <img id="mada" src="assets/images/mada.png" alt="">
        <img id="mada" src="assets/images/visa.png" alt="">
        <img id="mada" src="assets/images/mastercard.png" alt="">
      </div>
    </div>
    <p class="txt-caption">{{'COPY-RIGHTS' | translate}}</p>
    <button class="lang-switch flx-align-c" (click)="switchLang()">
      <p class="txt-xs fw-800">
        {{ "LANG" | translate }}
      </p>
      <i class="fi-globe txt-md"></i>
    </button>
  </div>
</ng-template>