import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthorized, isUnauthorized } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [isAuthorized],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'profile',
    canActivate: [isAuthorized],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'requests',
    canActivate: [isAuthorized],
    loadChildren: () =>
      import('./pages/requests/requests.module').then((m) => m.RequestsModule),
  },
  {
    path: 'offers',
    canActivate: [isAuthorized],
    loadChildren: () =>
      import('./pages/offers/offers.module').then((m) => m.OffersModule),
  },
  {
    path: 'payments',
    canActivate: [isAuthorized],
    loadChildren: () =>
      import('./pages/payments/payments.module').then((m) => m.PaymentsModule),
  },
  {
    path: 'login',
    canActivate: [isUnauthorized],
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'signup',
    canActivate: [isUnauthorized],
    loadChildren: () =>
      import('./pages/auth/signup/signup.module').then((m) => m.SignupModule),
  },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
