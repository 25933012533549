import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const tokenRefreshInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);
  return next(req).pipe(
    tap({
      next: () => {},
      error: (err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) return;
          else auth.logOut();
        }
      },
    })
  );
};
