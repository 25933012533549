import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  public isOpen = false;

  constructor(private translate: TranslateService, private router: Router) {
    this.setDefaultLang()
  }

  private setDefaultLang(): void {
    const lang = localStorage.getItem('lang') || 'ar';
    // update app direction
    const bodyEl: HTMLBodyElement | null = document.querySelector('body');
    bodyEl?.classList[lang == 'ar' ? 'add' : 'remove']('rtl');
    // use current language
    this.translate.setDefaultLang(lang);
  }

  public switchLang(): void {
    let lang = localStorage.getItem('lang');
    lang = lang == 'ar' ? 'en' : 'ar';
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  public get isLoggedIn(): boolean {
    return !(
      this.router.url.includes('/login') || this.router.url.includes('/signup')
    );
  }
}
