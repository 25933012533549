import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserService } from '../services/user.service';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const lang = localStorage.getItem('lang') || 'ar';
  const token = inject(UserService).getAccessToken();
  const reqWithHeaders = req.clone({
    headers: new HttpHeaders({
      'Accept-Language': lang,
      'Authorization': `Bearer ${token}`
    })
  });
  return next(reqWithHeaders);
};
